import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../UserContext';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { user, loading } = useUser();
  if (loading) {
    return null;
  }
  if (!user || !user.min) {
    return <Navigate to="/notfound" />;
  }
  return children;
};

export default ProtectedRoute;
